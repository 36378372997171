import * as React from "react";
import "./Field.scss";
import { FieldProps, FieldState } from "../../Interfaces/FormInterfaces";

export class Field extends React.Component<FieldProps, FieldState> {
    protected inner_input: any;
    protected identifier: string = '';

    constructor(props: FieldProps) {
        super(props);
        const value = !props.value ? '' : props.value;

        this.state = { newValue: value };
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleHitEnter = this.handleHitEnter.bind(this);
    }

    get myId() {
        if (this.identifier.length > 0) {
            return this.identifier;
        }
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.identifier = result;
        return this.identifier;
    }

    get contents() {
        const label = <label className={ "title" } htmlFor={ this.myId }>{ this.props.title }<span className={'required-indicator ' + (this.props.required ? 'show' : '')}>&nbsp;</span></label>;
        const input =
                this.props.inputType !== 'textarea' ? (
                    <input ref={ (input) => this.inner_input = input } type={ this.props.inputType }
                           defaultChecked={this.props.checked}
                           name={ this.props.name }
                           onChange={ this.onChange }
                           onBlur={ this.onBlur }
                           value={ this.state.newValue }
                           required={ this.props.required }
                           disabled={ this.props.disabled }
                           id={ this.myId }
                           autoComplete={ this.props.autoComplete ? 'on' : 'off' }
                    />
                ) : (
                    <textarea ref={ (input) => this.inner_input = input }
                           defaultChecked={this.props.checked}
                           name={ this.props.name }
                           onChange={ this.onChange }
                           onBlur={ this.onBlur }
                           data-value={ this.state.newValue }
                           required={ this.props.required }
                           disabled={ this.props.disabled }
                           id={ this.myId }
                    />
                );

        return (
            <React.Fragment>
                {input}
                {label}
            </React.Fragment>
        );
    }

    componentDidMount () {
        document.addEventListener('keydown', this.handleHitEnter, true)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleHitEnter, true)
    }

    handleHitEnter(e: any) {
        const ENTER_KEY_CODE = 13;
        if (this.props.inputType === 'textarea' &&
            (e.key === 'Enter' || e.keyCode === ENTER_KEY_CODE)) {
            e.stopPropagation()
        }
    }

    onChange(e: any) {
        const { target } = e;
        const { type } = target;
        const newValue = type === 'checkbox' ? target.checked : target.value;

        const validationErrors = this.validate(newValue);
        this.setState({ ...this.state, validationErrors: validationErrors, newValue: newValue });
    }

    onBlur(e: any) {
        const { target } = e;
        const { type } = target;
        const newValue = type === 'checkbox' ? target.checked : target.value;

        const validationErrors = this.validate(newValue);

        this.setState({ ...this.state, validationErrors: validationErrors });
    }

    validate(value: any) {
        if (this.props.required) {
            if (!value) {
                return "Dit is een verplicht veld.";
            }

            let oneRadioIsChecked = false;
            if (this.props.inputType === "radio") {
                const radios = document.getElementsByName(this.props.name);
                for (let i = 0, length = radios.length; i < length; i++) {
                    if ((radios[ i ] as HTMLInputElement).checked) {
                        // do whatever you want with the checked radio
                        oneRadioIsChecked = true;

                        // only one radio can be logically checked, don't check the rest
                        break;
                    }
                }
                if (!oneRadioIsChecked)
                    return "Dit is een verplicht veld.";
            }

        }

        if (this.props.validator) {
            const customErr = this.props.validator(value);
            if (customErr)
                this.inner_input.setCustomValidity(customErr);
            else
                this.inner_input.setCustomValidity("");
            return customErr;

        }

        return undefined;
    }


    render() {
        const classes = ['field', this.props.inputType];
        if (this.state.validationErrors) {
            classes.push('invalid');
        }
        return (
            <div className={ classes.join(' ') }>
                <div className={ "field-content" }>
                    {this.contents}
                </div>
            </div>
        );
    }
}
