import React, { ReactElement, useEffect, useRef, useState } from "react";
import "./SubmissionForm.scss";
import { wp } from "../../Factories/Wordpress";
import { Form } from "../../containers/Form/Form";

interface IProps {
    formName: string
    whenDone?: () => void
    before?: ReactElement|ReactElement[]|string
    children: ReactElement|ReactElement[]|string
    showWhenDone?: ReactElement|ReactElement[]|string
    className?: string
    buttonText?: string
    buttonClass?: string
    leaveOpen?: boolean
}

export function SubmissionForm(props: IProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);

    const elm = useRef(null);

    const onSubmit = (data: any) => {
        data.form = props.formName;

        setIsLoading(true);
        wp().submissions.post(data).then((answer: any) => {
            setIsLoading(false);
            setDone(true);
        }).catch(() => {
            setIsLoading(false);
            setDone(false);
        });
    };

    useEffect(() => {
        if (done && props.whenDone) {
            props.whenDone();
            if (!props.leaveOpen) {
                setTimeout(() => {
                    setIsLoading(false);
                    setDone(false);
                }, 5000);
            }
        }
    }, [props, done]);

    const classes = ['submission-form-wrapper'];
    if (props.className) {
        classes.push(props.className);
    }
    if (isLoading) {
        classes.push('loading');
    }
    if (done) {
        classes.push('done');
    }
    if ((isLoading || done) && elm && elm.current !== null) {
        const div = elm.current as any as HTMLDivElement;
        (div.parentElement as HTMLDivElement).scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const makeClassName = (className: string) => className + ' form-' + props.formName.toLowerCase();


    return (
        <div className={classes.join(' ')} ref={elm}>
            <div className={makeClassName("submission-form")}>
                {props.before}
                <Form onSubmit={onSubmit}
                      name={props.formName.toLowerCase()}
                      buttonText={props.buttonText}
                      buttonClass={props.buttonClass}
                >
                    {props.children}
                </Form>
            </div>
            <div className={makeClassName("submission-form-loading")}>
                <h1>Verzenden...</h1>
            </div>
            <div className={makeClassName("submission-form-done")}>
                {props.showWhenDone ? props.showWhenDone : (<h1>Bericht succesvol verzonden!</h1>)}
            </div>
        </div>
    )
}
