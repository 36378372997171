import React, { ReactNode, useEffect, useRef } from "react";
import "./Part.scss";

interface IProps {
    children: ReactNode
    variant?: 'normal' | 'reversed' | 'dark'
    className?: string
    headerBackground?: 'primary' | 'secondary'
}

export function Part(props: IProps) {
    const ref =  useRef(null);
    const classes = [
        'part-wrapper',
        ...[props.variant],
        ...[props.className]
    ];

    useEffect(() => {
        if (ref.current && props.headerBackground) {
            const div = ref.current as any as HTMLDivElement;
            const headerBar = div.querySelector('.header-bar-background') as any as HTMLDivElement;

            const handleScroll = () => {
                // console.log(Math.min(76, Math.max(0, (div.getBoundingClientRect().top - 76) * -1)));
                const headerBarSize = Math.min(76, Math.max(0, (div.getBoundingClientRect().top - 76) * -1));
                const percentage = headerBarSize / 76;
                headerBar.style.transform = `scaleY(${percentage})`;
                // headerBar.style.height = `${headerBarSize}px`;
            };
            handleScroll();
            document.addEventListener('scroll', handleScroll);

            return () => {
                document.removeEventListener('scroll', handleScroll);
            }

        }
    }, [ref, props.headerBackground]);

    return (
        <div className={classes.join(' ')} ref={ref}>
            {props.children}
            {props.headerBackground ? <>
                <div className={`header-bar-background-wrapper ${props.headerBackground}`}>
                    <div className="header-bar-background" />
                </div>
            </> : <></>}

        </div>
    )
}
