import React, { ReactNode, useEffect, useRef } from "react";
import "./ParallaxWrapper.scss";

interface IProps {
    distance?: number
    children: ReactNode
}

export function ParallaxWrapper(props: IProps) {
    const ref =  useRef(null);

    const distance = props.distance ?? 200;

    useEffect(() => {
        if (ref.current) {
            const div = ref.current as any as HTMLDivElement;
            const child = div.children[0] as any as HTMLImageElement;

            const handleScroll = () => {
                const pos = div.offsetHeight - div.getBoundingClientRect().top;
                const percentage = pos / div.offsetHeight;

                child.style.marginTop = `${percentage * distance - distance}px`;
            };
            handleScroll();
            document.addEventListener('scroll', handleScroll);

            return () => {
                document.removeEventListener('scroll', handleScroll);
            }

        }
    }, [ref, distance]);

    return (
        <div className="parallax-wrapper" ref={ref} data-distance={distance}>
            {props.children}
        </div>
    )
}
