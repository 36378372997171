import React from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import { HeaderBar } from "../../components/HeaderBar/HeaderBar";
import { Home } from "../../Pages/Home/Home";

interface IProps {

}

export function Router(props: IProps) {
    return (
        <BrowserRouter>
            <HeaderBar />
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </BrowserRouter>
    )
}
