import "./Button.scss";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface IProps {
    variant?: 'default' | 'dark'
    className: string
    onClick?: () => void
    children: ReactNode|ReactNode[]|string
    to?: string
    disabled?: boolean
    type?: 'submit' | 'reset' | 'button'
}

export function Button(props: IProps) {
    const classes = props.className.split(' ');

    if (props.variant) {
        classes.push(`variant-${props.variant}`)
    }

    if (props.to) {
        return (
            <Link aria-disabled={props.disabled} to={props.to} className={classes.join(' ')}>
                {props.children}
            </Link>
        );
    } else {
        return <button type={props.type} disabled={props.disabled} className={classes.join(' ')} onClick={props.onClick}>
            {props.children}
        </button>
    }
}
