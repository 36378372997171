import Wordpress from "squde-wp-api";

export interface ISlugResult {
    ID: string
    type: string
}

class CustomWordpress extends Wordpress {
    get slug() {
        const api = this;
        return {
            get: (params: { [s: string]: string; } = {}, signal: any = null): Promise<ISlugResult> => {
                return api.get('slug/?' + api.buildQuery(params), signal)
                    .then((result: any) => result);
            }
        }
    }

    get auth2() {
        const api = this;
        return {
            forgot: (email: string, signal: any = null): Promise<any> => {
                return api.post('user/forgotPassword', {user_login: email}, signal)
                    .then((response: any) => {
                        if ('done' in response) {
                            return response;
                        }
                        throw response;
                    });
            },
            resetPassword: (params: { [s: string]: string; } = {}, signal: any = null): Promise<any> => {
                return api.post('user/resetPassword', params, signal);
            }
        };
    }
}
export function wp() {
    const endpoints = {
        dev: 'http://eps-presentation.local',
        // test: 'https://eps.squde.nl',
        prod: 'https://malotimmerwerken.nl'
    };
    let endpoint;
    const host = window.location.host;
    if (host === 'malotimmerwerken.nl') {
        endpoint = endpoints.prod;
    } else {
        endpoint = endpoints.dev
    }
    return new CustomWordpress(endpoint + "/wp-json/wp/v2/");
}
