import React, { MediaHTMLAttributes, useEffect, useRef } from "react";

interface IProps extends MediaHTMLAttributes<any>{
    behaviour: 'frame' | 'play';
}

export function ScrollVideo(props: IProps) {
    const {
        behaviour,
        ...videoProps
    } = props;

    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            const video = ref.current as any as HTMLVideoElement;

            const handleScroll = () => {
                const pos = video.offsetHeight - video.getBoundingClientRect().top;
                const percentage = Math.min(pos / video.offsetHeight, 2) / 2;
                if (behaviour === 'frame') {
                    video.currentTime = Math.round(video.duration * percentage) || 0;
                } else if (behaviour === 'play') {
                    if (percentage === 0 || percentage === 1) {
                        video.pause();
                        video.currentTime = 0;
                    } else {
                        video.play();
                    }
                }
            }
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            }
        }
    }, [behaviour, ref]);

    return <video muted={true} {...videoProps} ref={ref} />;
}
