import React, { useState } from "react";
import "./ContactForm.scss";
import { SubmissionForm } from "../../SubmissionForm/SubmissionForm";
import { Field } from "../../../containers/Field/Field";

interface IProps {

}

export function ContactForm(props: IProps) {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });


    return <SubmissionForm leaveOpen={true} before={(
        <>
            <p>Bel ons op  +31 6 11 25 39 74 of vul het onderstaande formulier in.</p>
        </>
    )} formName={'Contact'} buttonText={"Verzenden"} whenDone={() => {
        setTimeout(() => {
            // setOpen(false);
        }, 5000);
    }}>
        <section>
            <Field name="name" title="Naam" inputType="text" required={true} value={formData.name} />
            <Field name="phone" title="Telefoonnummer" inputType="phone" value={formData.phone} />
            <Field name="email" title="Email" inputType="text" required={true} value={formData.email} />
            <Field name="message" title="Bericht" inputType="textarea" />

            <Field name={"agreements"} title={"Ik ga akkoord dat mijn gegevens worden opgeslagen ten einde contact op te nemen"} inputType={"checkbox"} required={true} />
        </section>
    </SubmissionForm>;
}
