import React, { useEffect, useRef, useState } from "react";
import "./HeaderBar.scss";
import logoSmall from  "../../resources/svg/logo-small.svg"
import mail from  "../../resources/svg/mail.svg"
import phone from  "../../resources/svg/phone.svg"
import { Link } from "react-router-dom";

interface IProps {

}

export function HeaderBar(props: IProps) {
    const emailInput = useRef(null);
    const phoneInput = useRef(null);
    const [showPhone, setShowPhone] = useState(false);
    const [showMail, setShowMail] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const goUp = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        setMenuOpen(false);
    }

    const goToContact = () => {
        const contactWrapper = document.getElementById('contact-wrapper') as any as HTMLDivElement;
        window.scrollTo({top: contactWrapper.getBoundingClientRect().top + window.scrollY, behavior: 'smooth'});
        setMenuOpen(false);
    }

    useEffect(() => {
        if (showMail && emailInput.current) {
            (emailInput.current as HTMLInputElement).select();
            (emailInput.current as HTMLInputElement).focus();
        }
    }, [emailInput, showMail]);

    useEffect(() => {
        if (showPhone && phoneInput.current) {
            (phoneInput.current as HTMLInputElement).select();
            (phoneInput.current as HTMLInputElement).focus();
        }
    }, [phoneInput, showPhone]);

    return (
        <div className={`header-bar-wrapper ${menuOpen ? 'menu-open' : ''}`}>
            <div className={'header-bar-left'}>
                <img src={logoSmall} alt="logo"/>
                <ul className={'buttons'}>
                    <li>
                        <Link onClick={goUp} to={'/'}>Home</Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={'/'}>Projecten</Link>*/}
                    {/*</li>*/}
                    <li>
                        <Link onClick={goToContact} to={'/'}>Contact</Link>
                    </li>
                </ul>
            </div>
            <ul className="icons">
                <li>
                    <a href="mailto:info@malotimmerwerken.nl" onClick={(event) => {
                        // event.preventDefault();
                        setShowPhone(false);
                        setShowMail(!showMail);
                    }}>
                        <img src={mail}/>
                    </a>
                    <div className={`balloon ${showMail ? 'show' : ''}`}>
                        <h4>Email</h4>
                        <input type="text" readOnly={true} value={'info@malotimmerwerken.nl'} ref={emailInput} onBlur={() => {
                            setShowMail(false);
                        }}/>
                    </div>
                </li>
                <li>
                    <a href="tel:0611253974" onClick={(event) => {
                        // event.preventDefault();
                        setShowMail(false);
                        setShowPhone(!showPhone);
                    }}>
                        <img src={phone}/>
                    </a>
                    <div className={`balloon ${showPhone ? 'show' : ''}`}>
                        <h4>Telefoonnummer</h4>
                        <input type="text" readOnly={true} value={'06 11 25 39 74'} ref={phoneInput} onBlur={() => {
                            setShowPhone(false);
                        }}/>
                    </div>
                </li>
                <li className="mobile-only">
                    <a href=""  onClick={(e) => {
                        e.preventDefault();
                        setMenuOpen(!menuOpen);
                    }}>
                        <span className={`menu-button ${menuOpen ? 'open' : ''}`}>
                            <div/>
                            <div/>
                            <div/>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    )
}
