import React from "react";
import {FormProps} from "../../Interfaces/FormInterfaces";
import { Button } from "../../components/Button/Button";

export class Form extends React.Component<FormProps, any> {
    protected inner_form: any;

    constructor(props: FormProps) {
        super(props);
        this.doSubmit = this.doSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount(): void {
        const fields = this.getChildrensValues(this);
        this.setState({fields});
        if (this.props.onChange) {
            this.props.onChange(fields, false);
        }
    }

    getChildrensValues(item: any) {
        let fields = {} as any;
        if (item.props && item.props.children) {
            React.Children.forEach(item.props.children,
                (subItem: any) => {
                    const name = subItem.props.name as string;
                    if (subItem.type === 'div') {
                        const otherFields = this.getChildrensValues(subItem);
                        fields = {...fields, ...otherFields};
                    } else if (name) {
                        let value = subItem.props.value;

                        if ((subItem.props.inputType || subItem.props.type) && (subItem.props.inputType || subItem.props.type) === 'checkbox') {
                            this.getCheckboxValue(subItem);
                        }
                        if (subItem.type === 'textarea' || subItem.props.inputType !== 'radio' || subItem.props.checked === true) {
                            fields[name] = value;
                        }
                    }
                });
        }
        return fields;
    }

    getReactChildren(item: any) {
        let children: any[] = [];
        if (item.props && item.props.children) {
            React.Children.forEach(item.props.children,
                (subItem: any) => {
                    const name = subItem.props.name as string;
                    if (subItem.type === 'div') {
                        const otherChildren = this.getReactChildren(subItem);
                        children = [...children, ...otherChildren];
                    } else if (name) {
                        children.push(subItem);
                    }
                });
        }
        return children;
    }


    doSubmit(e?: any) {
        if (e) {
            e.preventDefault();
        }

        if (this.validateEverything()) {
            this.props.onSubmit(this.filterUndefined(this.state.fields));
        }
    }

    filterUndefined(data: any) {
        const newData = {} as any;
        for(let key in data) {
            if (data.hasOwnProperty(key) && data[key]) {
                newData[key] = data[key];
            }
        }
        return newData;
    }

    validateEverything() : boolean {
        let valid = true;
        Array.from(this.inner_form).forEach((input: any) => {
            input.dispatchEvent((new Event('blur')));
            if (valid && input.validity && !input.validity.valid) {
                valid = false;
            }
        });
        return valid;
    }

    handleChange(event: any) {
        const target = event.target;
        let value = target.type === "checkbox" ? (this.getCheckboxValue(target)) : target.value;
        const name = target.name.split('[').join('').split(']').join('');
        const updatedFields = { ...this.filterUndefined(this.state.fields) };
        updatedFields[ name ] = value;

        this.setState({ ...this.state, fields: updatedFields });


        if (this.props.onChange) {
            this.props.onChange(updatedFields);
        }
    }

    getCheckboxValue(checkbox: HTMLInputElement) {
        if (!checkbox.name) {
            return false;
        }
        if (checkbox.name.split('[').length > 1) {
            const inputs = document.getElementsByName(checkbox.name);
            const values: string[] = [];
            Array.from(inputs).forEach((elm: HTMLElement) => {
                const input = elm as HTMLInputElement;
                if (input.checked) {
                    values.push(input.value);
                }
            });
            return values.join(', ');
        } else {
            return checkbox.checked;
        }
    }

    get buttonText() {
        const { buttonText = 'Verzenden' } = this.props;
        return buttonText;
    }

    render() {
        return (
            <form ref={ (form) => this.inner_form = form } className="form"
                  onSubmit={ this.doSubmit }
                  onChange={ this.handleChange } noValidate={true}>
                { this.props.header ? <h1>{ this.props.header }</h1> : "" }
                {/*<em>Op dit moment is er even geen mogelijkheid om via de site contact met ons op te nemen.<br /><br />Onze excuses voor het ongemak.</em>*/}
                { this.props.children }
                <Button className={this.props.buttonClass || "button secondary"} type={"submit"} variant={'dark'} onClick={ this.doSubmit }>{this.buttonText}</Button>
            </form>
        );
    }
}
