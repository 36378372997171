import React from "react";
import "./Home.scss"
import { Part } from "../../containers/Part/Part";
import logo from "../../resources/svg/logo-big.svg";
import logoText from "../../resources/svg/logo-text.svg";
import { ScrollVideo } from "../../components/ScrollVideo/ScrollVideo";
import { ParallaxWrapper } from "../../components/ParallaxWrapper/ParallaxWrapper";
import { ContactForm } from "../../components/Forms/ContactForm/ContactForm";

interface IProps {

}

export function Home(props: IProps) {
    return (
        <div className="home-wrapper">
            <Part className={"splash"}>
                <div className="left-side">
                    <img src={logo} alt="Logo"/>
                </div>
                <div className="right-side">
                    <img src={logoText} alt="Logo txt"/>
                </div>
            </Part>
            <Part>
                <div className="left-side with-vignette">
                    <ParallaxWrapper>
                        <ScrollVideo loop={true} src="/video/IMG_2173_small.mov" behaviour="play" />
                    </ParallaxWrapper>
                </div>
                <div className="right-side content-container">
                    <div className="part-content">
                        <h1><b>Lorem Ipsum</b> dolor</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium consequuntur deserunt distinctio error ipsa iste magni maiores minus nulla, praesentium quis recusandae, reiciendis vel veniam voluptate! Beatae dolorum esse eum.</p>
                    </div>
                </div>
            </Part>
            <Part variant={'reversed'} headerBackground={'primary'}>
                <div className="left-side content-container">
                    <div className="part-content">
                        <h1>Uw <b>renovatie</b></h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium consequuntur deserunt distinctio error ipsa iste magni maiores minus nulla, praesentium quis recusandae, reiciendis vel veniam voluptate! Beatae dolorum esse eum.</p>
                    </div>
                </div>
                <div className="right-side">
                    <ParallaxWrapper distance={100}>
                        <img src="https://as1.ftcdn.net/v2/jpg/02/19/42/54/1000_F_219425449_Xb8tudxcRwebk9Os9Rfz4p1F0WHCHB6e.jpg" alt="renovatie" />
                    </ParallaxWrapper>
                </div>
            </Part>
            <Part>
                <div className="left-side">
                    <ParallaxWrapper>
                        <img src="https://as2.ftcdn.net/v2/jpg/00/99/93/47/1000_F_99934797_jnUHzNokYDLygFNEa5oP35t5Y3IegrMG.jpg" alt="blokschaaf" />
                    </ParallaxWrapper>
                </div>
                <div className="right-side content-container">
                    <div className="part-content">
                        <h1>Uw <b>verbouwing</b></h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium consequuntur deserunt distinctio error ipsa iste magni maiores minus nulla, praesentium quis recusandae, reiciendis vel veniam voluptate! Beatae dolorum esse eum.</p>
                    </div>
                </div>
            </Part>
            <Part variant={'dark'} headerBackground={'secondary'}>
                <div className="left-side content-container" id="contact-wrapper">
                    <div className="part-content">
                        <h1>Neem contact op</h1>
                        <div style={{overflow: 'hidden', maxWidth: 'calc(100vw - 50px)'}}>
                            <ContactForm />
                        </div>
                    </div>
                </div>
                <div className="right-side" />
            </Part>
        </div>
    )
}
