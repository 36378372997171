import React from 'react';
import './App.css';
import { Router } from "./containers/Router/Router";

function App() {
    return (
        <Router />
    );
}

export default App;
